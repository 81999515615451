import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import backgroundImage from '../Images/Digital-Solutions-BG.jpg';
import Boxes from './Boxes';

const HomeLayout = () => {
  return (
    <Box       
      className="homeLayoutContent"
    >
     
        <Boxes />
    </Box>
  );
};

export default HomeLayout;
