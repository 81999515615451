import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@mui/system'; // Import keyframes for animation

// Styled Box for the overall container
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  
  padding: '8px',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', // Stack content vertically on smaller screens
    textAlign: 'center',
  },
  borderRadius:'12px',
}));

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px); // Start slightly below
  }
  100% {
    opacity: 1;
    transform: translateY(0); // End at normal position
  }
`;
const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;
const pulse = keyframes`
  0% {
    transform: scale(1); // Normal size
  }
  50% {
    transform: scale(1.1); // Slightly bigger
  }
  100% {
    transform: scale(1); // Back to normal
  }
`;
const CallToActionBox = ({ message, buttonText, onButtonClick }) => {
  return (
    <Box sx={{margin:{xl:'0 auto'}, marginBottom:'20px', marginTop:'20px',padding:{xl:'0 16px'}, width:{md:'100%'}}}>
    <StyledBox sx={{display: 'flex', flexDirection:'column'}}>
      {/* Message */}
      <Typography variant="body1" sx={{ fontWeight: 500,color:'#fff', fontSize:{xs:'1.15rem', md:'1.5rem'},textAlign:{xs:'center'} }}>
        {message}
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        sx={{
          mt:2,
          borderRadius:'20px',
          textTransform: 'none',
          padding: '8px 24px',
          border:'1px solid slategray',
          backgroundImage: 'linear-gradient(125deg, #494594, #8a348c) !important',   
          animation: `${pulse} 1s ease-in-out infinite`,       
          '&:hover': {    
            transform: 'scale(1.1)', 
            backgroundColor: '#002e5c',
          },
        }}
      >
        {buttonText}
      </Button>
    </StyledBox>
    </Box>
  );
};

export default CallToActionBox;
