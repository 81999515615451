import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled Box for consistent styling across sectors
const SectorBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#c0d9e6',// '#DBD7FA',
  borderRadius: '8px',
  textAlign: 'left',
  minHeight: {xs:'66px' , md: '90px'}, // Default min-height (for mobile, or xs screens)
  [theme.breakpoints.up('md')]: {
    minHeight: '90px', // When the screen size is 'md' and up
  },
}));

const SectorsGrid = ({ title = 'Sectors', sectors = [], spacing = { xs: 0, md: 3 }, itemBreakpoints = { xs: 12, sm: 6, md: 4, lg: 2.4 } }) => {
  return (
    <Box sx={{ flexGrow: 1, padding: {xs:0, xl:2},mt:1, mb:1}}>
      <Typography variant="h5" textAlign="center" gutterBottom sx={{fontWeight:600,fontSize:{xs:'1.15rem',md:
        '1.75rem'},mb:1,}}>
        {title}
      </Typography>
      <Grid 
        container 
        justifyContent="center" 
        spacing={spacing}  // Customizable spacing from props
      >
        {sectors.map((sector, index) => (
          <Grid
            item
            key={index}
            {...itemBreakpoints}  // Customizable breakpoints for grid items
          >
            <SectorBox>
              <Typography variant="h6" sx={{fontWeight:600,fontSize:"1.1rem"}}>{sector.title}</Typography>
              <Typography variant="body2" sx={{fontSize:{xs:"0.875rem", md:'1rem'}, lineHeight:{md:'1.2'}}}>{sector.description}</Typography>
            </SectorBox>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SectorsGrid;
