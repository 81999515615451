import React , { useState } from 'react'
import Grid from '@mui/material/Grid';
import { AppBar, Toolbar, Tabs, Tab } from "@mui/material";
import logo from "../Images/logo.png";

import img1 from '../Images/pratritiiu-1.jpg'
import carAcc from '../Images/car-accident.jpg'
import rustImg from '../Images/3d-rendering.jpg'
import resumeImg from '../Images/application-contact.jpg'
import vibrationImg from '../Images/service-maintenance-worker.jpg'
import SmallBox from './SmallBox';
import SectorsGrid from './SectorsGrid';
import boxBackground from '../Images/Digital-Solutions-BG.jpg';
import { Box, Typography, Button, Card } from '@mui/material';
import TagsSelector from './TagsSelector'; 
import CallToActionBox from './CallToActionBox'; 
import { keyframes } from '@mui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Boxes = () => {

  const isMobile = window.innerWidth <= 768; // Adjust the width as per your needs
  console.log("test isMobile:", isMobile);
  const cdd = "https://innovations.pratititech.com/visionai-car-damage-detection/";
  const irp = "http://innovations.pratititech.com/irp/";
  const rust = "https://innovations.pratititech.com/visionai-rust-detection-for-equipment/"
  const sectorsDataCDD = [
    { title: 'Manufacturing', description: 'Automated defect detection in production lines and products.' },
    { title: 'Healthcare', description: 'Assisting in the analysis of medical images for diagnosis.' },
    { title: 'Smart Buildings', description: 'Monitoring structural integrity and identifying potential damages.' },
    { title: 'Energy and Utilities', description: 'Assessing infrastructure damage after extreme weather or wear.' },
    { title: 'Sustainability', description: 'Proper waste sorting to encourage sustainable waste practices.' }
  ];

  const sectorsDataRP = [
    { title: 'Manufacturing', description: 'Automating talent allocation, skills-matching for production roles.' },
    { title: 'Healthcare', description: ' Credential verification, patient history parsing.' },
    { title: 'Smart Buildings', description: 'Workforce scheduling optimization for equipment performance and maintenance.' },
    { title: 'Energy and Utilities', description: 'Talent placement for critical infrastructure projects.' },
    { title: 'Sustainability', description: 'Supporting hiring for sustainable development and green energy roles.' }
  ];

  const sectorsDataRD = [
    { title: 'Manufacturing', description: 'Automated visual inspections for quality control, wear detection.' },
    { title: 'Healthcare', description: 'Corrosion detection in medical instruments and devices.' },
    { title: 'Smart Buildings', description: 'Monitoring structural integrity and infrastructure degradation.' },
    { title: 'Energy and Utilities', description: 'Inspecting transmission lines, power stations, and pipelines.' },
    { title: 'Sustainability', description: 'Detecting empty rooms to adjust lighting, cooling and HVACs.' }
  ];

  const sectorsDataRE = [
    { title: 'Manufacturing', description: 'Real-time equipment health monitoring, predictive maintenance, quality control.' },
    { title: 'Healthcare', description: 'Anomaly detection in medical equipment operation.' },
    { title: 'Smart Buildings', description: 'Monitoring HVAC systems and building infrastructure.' },
    { title: 'Energy and Utilities', description: 'Predicting failure in turbines, generators, and other key equipment.' },
    { title: 'Sustainability', description: 'Monitoring energy usage to prevent overconsumption and improve resource efficiency.' }
  ];

  const handleClickCDD = () => {
    window.open(cdd, "_blank", "noopener,noreferrer"); 
  };

  const handleClickRust = () => {
    window.open(rust, "_blank", "noopener,noreferrer"); 
  };

  const handleClickRP = () => {
    console.log("irp", irp)
    window.open(irp, "_blank", "noopener,noreferrer"); 
  }
  const [value, setValue] = useState(0);
  const [selectedTag, setSelectedTag] = useState('All Services');

  // Sample data for the tags
  const tagsCD = [
    'YOLO',
    'EfficientDet',
    'RetinaNet',
    'Faster R-CNN',
  ];

  const tagsRD = [
    'YOLO',
    'SOLO',
    'Mask R-CNN',    
    'UNet',
    'SAM',
  ];
  const tagsRP = [
    'NLP',
    'Transformer NER',
    'BERT-NER',   
  ];
  const tagsRE = [
    
  ];
  const handleContactClick = () => {
    window.open("https://www.pratititech.com/contact-us/", "_blank"); // Opens the URL in a new tab
   };
  // Handler for clicking a tag
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px); /* Move up */
  }
  60% {
    transform: translateY(-5px); /* Move up slightly less */
  }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(0, 0, 255, 0.2); }
  50% { box-shadow: 0 0 20px rgba(0, 0, 255, 0.8); }
  100% { box-shadow: 0 0 5px rgba(0, 0, 255, 0.2); }
`;
  return (
    <Box>
      <AppBar sx={{ background: "white", height: "65px", padding:{xs:'0 16px'} }}>
        <Toolbar sx={{ display: "flex", paddingY: "auto", height: '100%', position: 'relative',pl:0, pr:0 }}>
          <Grid
            container
            direction={'row'}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              p:0
            }}
            xs={12}
          >
            
              <Grid sx={{pl:{xl:'11%'}, }} xs={7}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ maxWidth: "140px", maxHeight: "65px", width: '20vw', height: '95%', position: 'relative',}}
                  onClick={() => window.location.href = 'https://www.pratititech.com/'}                  
                />
              </Grid>
              <Grid sx={{ marginY: 'auto',display:'flex',justifyContent:'flex-end',pr:{xl:'11%'} }} item xs={5}>
              
              <Button
            onClick={handleContactClick}
            sx={{
              textTransform: 'none',
              fontSize:'1.125rem',
              color: 'grey', // Set the text color to grey
            }}
          >
            Contact Us
          </Button>        
              </Grid>
            </Grid>          
        </Toolbar>
      </AppBar>

      <Box sx={{
        position: 'relative',  // Position the content relative to the image
        width: '100%',
        height: '100%',
        mt:{xs:'66px', md:'0'}
      }}>

        <img src={img1} style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',  // Ensure the image covers the container properly
        }} />
        <Typography
          sx={{
            fontSize: { xs: '18px', sm: '30px', md: '36px', lg: '40px' }, // Responsive font size
            position: 'absolute',
            fontFamily: "Heebo",
            top: { xs: '40%', sm: '42%', md: '45%' }, // Responsive top positioning
            left: { xs: '5%', sm: '8%', md: '5%',xl:'12%' },  // Responsive left margin          
            color: '#fff',            
            fontWeight: '500',
          }}
        >
          Step into the Future with our AI Innovation Hub!
        </Typography>      
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack items vertically
          justifyContent: 'space-between', // Evenly distribute space vertically         
          height: '100%', // Take full height of the parent container         
         // padding: "3% 10%", // Add padding as needed
          gap: {
            xs: 1, // Small gap for extra-small screens (mobile)
            sm: 2, // Slightly larger gap for small screens (tablet)
            md: 3, // Medium gap for medium screens (laptop)           
          },

        }}
      >

<Typography item sx={{
           fontSize: "24px", color: 'white', fontFamily: "Roboto, sans-serif",width:{xs:'90%',xl:'77%'},margin:'0 auto',mt: {
            xs: 1, // Small gap for extra-small screens (mobile)
            sm: 2, // Slightly larger gap for small screens (tablet)
            md: 3, // Medium gap for medium screens (laptop)           
          },
        }}>Explore our AI Innovation Hub</Typography>
       
       {/* <Box sx={{ width:{xs:'90%',xl:'77%'},margin:'0 auto', }}>
   
    <Tabs 
        value={value} 
        onChange={handleChange} 
        aria-label="AI Innovation Hub tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          '& .MuiTab-root': {
            textTransform: 'none',       // Removes uppercase transformation
            fontWeight: 'bold',          // Makes the text bold
            color: '#c2c3c2',            // Default text color
            fontSize: '16px',            // Font size
            backgroundColor: '#6c757d',
          },
          '& .MuiTab-root.Mui-selected': {
            color: '#8a348c',            // Color when tab is selected
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#8a348c',  // The underline indicator color for active tab
          },
        }}
      >
        <Tab label="Smarter Car Damage Detection" />
        <Tab label="Advanced AI-Powered Rust Detection" />
        <Tab label="Efficient Resume Parsing" />
        <Tab label="Advanced Vibration Analysis in Predictive Maintenance" />
      </Tabs>
      
     
      <TabPanel value={value} index={0}>
       
        <Typography variant="h5">Committed to Customer Success</Typography>
        <p>Content related to customer success and how AI solutions help achieve it.</p>
      </TabPanel>

      <TabPanel value={value} index={1}>
        
        <Typography variant="h5">Engineered with Innovation</Typography>
        <p>Details on innovative AI technologies in use.</p>
      </TabPanel>

      <TabPanel value={value} index={2}>
       
        <Typography variant="h5">Formulated for Agility</Typography>
        <p>How AI drives agility and flexibility in operations.</p>
      </TabPanel>

      <TabPanel value={value} index={3}>
      
        <Typography variant="h5">Delivered with Certainty</Typography>
        <p>How AI ensures reliable and consistent outcomes.</p>
      </TabPanel>
      
</Box> */}
{/* <Box sx={{backgroundImage:`url(${boxBackground})`,}}>  */}
<Box sx={{backgroundColor:'#f2f2f2',}}>

        <Grid container xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row', // Align image and text horizontally
            alignItems: 'top', // Center vertically
              gap:{xs:0.5, xl:0}, // Add space between text and image
            bgcolor: "white",
            justifyContent: "space-between",
            margin:'0 auto',
            width:{xs:'100%',xl:'77%'},
            backgroundColor:'#f2f2f2',
            padding:{xs:"20px", md:"20px"}
          }}
        >
      
          {/* Additional Image */}
          <Grid item xs={12} xl={5} sx={{ alignContent: "flex-end", justifyContent: "flex-end",}}>
            <img src={carAcc} alt="AI Performance" style={{ height: isMobile ? '30vh' : '100%', width:'100%', }} />
          </Grid>
          {/* Additional Text */}
          <Grid item xs={12} xl={7} sx={{padding:{md:'20px'}, color: 'black',               
                fontFamily: "Roboto",}}>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: {xs:'1.35rem', xl:'1.75rem'},               
                marginBottom: '20px', // Add space below this Typography

              }}
            >
              VisionAI Based Car Damage Detection
            </Typography>
            <div>
      <TagsSelector
       approachContent="Image Pre-processing → Object Detection Model Training → Damage Detection → Damage Assessment → Post Processing"
        title="AI models used"
        tags={tagsCD}
        selectedTag={selectedTag}
        onTagClick={handleTagClick}
      />
    </div>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: "1.125rem",
               
              }}
            >Harness the Power of AI for Smarter Car Damage Detection</Typography> 
            <Typography
              sx={{
                
                fontSize: "1rem",
                marginBottom: '16px', // Add space below this Typography
              }}
            >
              We leverage cutting-edge VisionAI technology to revolutionize car damage assessment. By replacing traditional inspection methods with
               AI-powered image analysis, we deliver unparalleled speed, accuracy, and consistency. Our advanced algorithms automatically detect and 
               classify vehicle damage, minimizing human error and providing precise results. This AI-driven approach not only streamlines workflows but 
               also enhances transparency and trust in the inspection process. </Typography>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: "18px",               
                marginBottom: '8px', // Add space below this Typography
              }}
            >
             Why Choose Our AI Technology?
            </Typography>
            <Box sx={{ paddingLeft: 2 }}>
              {/* First Point */}
              <Typography
                sx={{                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Accurate Damage Detection:
                </Typography>
                &nbsp;AI identifies and classifies damages with precision.
              </Typography>

              {/* Second Point */}
              <Typography
                sx={{
                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{  fontWeight: 'bold' }}
                >
                  Fraud Prevention:
                </Typography>
                &nbsp;Transparent assessment reduces human error and potential fraud.
              </Typography>

              {/* Third Point */}
              <Typography
                sx={{                  
                  marginBottom: 3,
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Faster Claims Processing:
                </Typography>
                &nbsp;Streamlined damage evaluations speed up insurance or repair workflows.
              </Typography>
            </Box>

            <Typography
              sx={{                               
                marginBottom: 2,               
              }}
            >
              Reimagine vehicle inspection with AI! <strong>Experience our Car Damage Detection Demo </strong>and see how VisionAI can change the way you assess damage.
            </Typography>
            <Button
              variant="contained" onClick={handleClickCDD}
              sx={{
                fontWeight: "bold",
                padding: {
                  xs: '10px',  // Extra small screens
                  sm: '10px', // Small screens
                  md: '6px 40px', // Medium screens
                  lg: '8px 50px', // Large screens
                  xl: '10px 60px', // Extra-large screens
                },

                width: {
                  xs: '100%', // Full width for small screens
                  sm: 'auto', // Auto width for medium and large screens
                },
                background: 'linear-gradient(125deg, #494594, #8a348c)', // Default color
                transition: 'transform 0.3s ease',
                borderRadius: "15px",
              //  animation: `${bounce} 2s infinite`, // Apply the animation
              animation: `${glow} 2s ease-in-out infinite`,
                '&:hover': {
                  transform: 'translateY(-2px)', // Hover color
                },
              }}
            >
              Try now
            </Button>
          </Grid>
          {/* <SmallBox /> */}
          <SectorsGrid
            title="Transforming industries with our VisionAI versatility"
            sectors={sectorsDataCDD}
            spacing={{ xs: 2, md: 3 }}
            itemBreakpoints={{ xs: 12, sm: 6, md: 4, lg: 2.4 }}           
          />
        
  
        </Grid>
        </Box>   
        <CallToActionBox
        message=" We empower visionary solutions with Computer Vision! "
        buttonText="Contact us"
        onButtonClick={handleContactClick}
      />


              {/* resume parser commented */}
        {/* <Box sx={{backgroundColor:'#fff',}}> 
        <Grid container xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row', // Align image and text horizontally
            alignItems: 'top', // Center vertically
              gap:{xs:0.5, xl:0}, // Add space between text and image
            bgcolor: "white",
            justifyContent: "space-between",
            margin:'0 auto',
            width:{xs:'100%',xl:'77%'},
            padding:{xs:"20px", xl:"0"}
          }}
        >
          <Grid item xs={12} xl={7} sx={{padding:{md:'20px'}, color: 'black',               
                fontFamily: "Roboto",}}>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: {xs:'1.35rem', xl:'1.75rem'},             
                marginBottom: '20px', // Add space below this Typography
                mt:{xs:2,md:0},
              }}
            >
              AI based Resume Parser     </Typography>
              <div>
      <TagsSelector
       approachContent="Resume Pre-processing → NER Modelling → Resume Segmentation → Profile Classification → Skills & Roles Assessment → Post Processing"
        title="AI models used"
        tags={tagsRP}
        selectedTag={selectedTag}
        onTagClick={handleTagClick}
      />
    </div>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: "1.125rem",
               
              }}
            >Leverage Advanced AI for Efficient Resume Parsing</Typography> 
            <Typography
              sx={{
                
                fontSize: "1rem",
                marginBottom: '16px', // Add space below this Typography
              }}
            >
           We bring AI innovation to recruitment with our state-of-the-art resume parsing technology.
	    Powered by advanced natural language processing (NLP) and machine learning algorithms, our
	     AI-driven parser automates the extraction and organization of resume data with precision and speed. 
	     By analyzing and interpreting key information such as skills, experience, and qualifications, our AI 
	     seamlessly matches candidates to job criteria, drastically reducing the manual effort and time required 
	     in the screening process.
            </Typography>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: "18px",               
                marginBottom: '8px', // Add space below this Typography
              }}
            >
              AI Capabilities You Can Rely On:
            </Typography>
            <Box sx={{ paddingLeft: 2 }}>
              <Typography
                sx={{                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                 Automated Parsing and Interpretation:        </Typography>
                &nbsp;Our AI models quickly scan and categorize resume data, streamlining the initial review stage.
              </Typography>

              <Typography
                sx={{
                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{  fontWeight: 'bold' }}
                >
                 Accurate and Contextual Data Extraction:        </Typography>
                &nbsp;Using sophisticated NLP techniques, the system ensures accurate extraction of key candidate details, minimizing errors.
              </Typography>

              <Typography
                sx={{                  
                  marginBottom: 3,
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Intelligent Matching Algorithms:        </Typography>
                &nbsp;Our AI refines candidate-job matching with higher precision, helping recruiters focus on top talent faster.
              </Typography>
            </Box>

            <Typography
              sx={{           
                marginBottom: 2,               
              }}
            >
             Take your recruitment process to the next level! <strong>Explore our AI Resume Parser Demo</strong> and discover the future of hiring.
             </Typography>
            <Button
              variant="contained" onClick={handleClickRP}
              disabled
              sx={{
                fontWeight: "bold",

                padding: {
                  xs: '10px',
                  sm: '10px',
                  md: '6px 40px',
                  lg: '8px 50px',
                  xl: '10px 60px',
                },
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
               mb:2,
                transition: 'transform 0.3s ease',
                borderRadius: "15px",
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              Coming soon...
            </Button>
          </Grid>

        
 <Grid item xs={12} xl={5} sx={{ alignContent: "flex-end", justifyContent: "flex-end",  }}>
            <img src={resumeImg} alt="AI Performance" style={{ height: isMobile ? '30vh' : '100%', width:'100%', }} />
          </Grid>
          <SectorsGrid
            title="Transforming industries with our AI versatility"
            sectors={sectorsDataRP}
            spacing={{ xs: 2, md: 3 }}
            itemBreakpoints={{ xs: 12, sm: 6, md: 4, lg: 2.4 }}
          />
          
        </Grid>
</Box> 
 <CallToActionBox
        message="Transform your processes with AI-driven innovations!"
        buttonText="Contact us"
        onButtonClick={handleContactClick}
      /> */}
        {/* <Box sx={{backgroundImage:`url(${boxBackground})`,}}>   */}
        <Box sx={{backgroundColor:'#f2f2f2',}}>
        <Grid container xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row', // Align image and text horizontally
            alignItems: 'top', // Center vertically
              gap:{xs:0.5, xl:0}, // Add space between text and image
            bgcolor: "white",
            justifyContent: "space-between",
            margin:'0 auto',
            backgroundColor:'#f2f2f2',
            width:{xs:'100%',xl:'77%'},
            padding:{xs:"20px", xl:"0"}
          }}
        >

          {/* Additional Image */}
          <Grid item xs={12} xl={5} sx={{ alignContent: "flex-end", justifyContent: "flex-end",  }}>
            <img src={rustImg} alt="AI Performance" style={{ height: isMobile ? '30vh' : '100%', width:'100%', }} />
          </Grid>
          {/* Additional Text */}
          <Grid item xs={12} xl={7} sx={{padding:{md:'20px'}, color: 'black',               
                fontFamily: "Roboto",}}>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: {xs:'1.35rem', xl:'1.75rem'},            
                marginBottom: '20px', // Add space below this Typography

              }}
            >
              VisionAI based Rust Detection for Equipment   </Typography>
              <div>
      <TagsSelector
       approachContent="Image Pre-processing → Object Segmentation Model Training → Rust Detection → Rust Assessment → Post Processing"
        title="AI models used"
        tags={tagsRD}
        selectedTag={selectedTag}
        onTagClick={handleTagClick}
      />
    </div>
              <Typography
              sx={{
                fontWeight: '600',
                fontSize: "1.125rem",
               
              }}
            >Enhance Asset Protection with Advanced AI-Powered Rust Detection:</Typography> 
            <Typography
              sx={{
                
                fontSize: "1rem",
                marginBottom: '16px', // Add space below this Typography
              }}
            >
             We employ cutting-edge VisionAI technology to detect rust and corrosion with precision and speed. Leveraging advanced computer 
             vision algorithms, our AI system analyzes real-time images and video feeds to identify rust in its earliest stages. By automating
              the detection process, our technology allows for rapid identification of potential issues, empowering maintenance teams to take 
              action before damage escalates. </Typography>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: "18px",               
                marginBottom: '8px', // Add space below this Typography
              }}
            >
             Why Choose Our AI Technology?
            </Typography>
            <Box sx={{ paddingLeft: 2 }}>
              {/* First Point */}
              <Typography
                sx={{                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Automated Inspection: </Typography>
                &nbsp;Real-time detection of rust and corrosion without manual intervention.
              </Typography>

              {/* Second Point */}
              <Typography
                sx={{
                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{  fontWeight: 'bold' }}
                >
                  Enhanced Safety: </Typography>
                &nbsp; Prevent equipment failures by acting before corrosion spreads.
              </Typography>

              {/* Third Point */}
              <Typography
                sx={{                  
                  marginBottom: 3,
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Cost Efficiency: </Typography>
                &nbsp;Reduce repair costs and extend asset life with proactive maintenance.
              </Typography>
            </Box>

            <Typography
              sx={{                           
                marginBottom: 2,               
              }}
            >
              Don’t let rust compromise your equipment! <strong>Test our Rust Detection Demo </strong> and see how VisionAI can boost your asset management strategy.       </Typography>
              <Button
              variant="contained" onClick={handleClickRust}
              sx={{
                fontWeight: "bold",
                padding: {
                  xs: '10px',  // Extra small screens
                  sm: '10px', // Small screens
                  md: '6px 40px', // Medium screens
                  lg: '8px 50px', // Large screens
                  xl: '10px 60px', // Extra-large screens
                },

                width: {
                  xs: '100%', // Full width for small screens
                  sm: 'auto', // Auto width for medium and large screens
                },
                background: 'linear-gradient(125deg, #494594, #8a348c)', // Default color
                transition: 'transform 0.3s ease',
                borderRadius: "15px",
              //  animation: `${bounce} 2s infinite`, // Apply the animation
              animation: `${glow} 2s ease-in-out infinite`,
                '&:hover': {
                  transform: 'translateY(-2px)', // Hover color
                },
              }}
            >
              Try now
            </Button>

          </Grid>
          <SectorsGrid
            title="Transforming industries with our VisionAI versatility"
            sectors={sectorsDataRD}
            spacing={{ xs: 2, md: 3 }}
            itemBreakpoints={{ xs: 12, sm: 6, md: 4, lg: 2.4 }}
          />
         
        </Grid>
        </Box>
        {/* <CallToActionBox
        message="Unlock the potential of Computer Vision today!"
        buttonText="Contact us"
        onButtonClick={handleContactClick}
      /> */}



      {/* commented vibration analysis */}
        {/* <Box sx={{backgroundColor:'#fff',}}>
        <Grid container xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row', // Align image and text horizontally
            alignItems: 'top', // Center vertically
              gap:{xs:0.5, xl:0}, // Add space between text and image
            bgcolor: "white",
            justifyContent: "space-between",
            margin:'0 auto',
            mt:{xs:2,md:0},
            width:{xs:'100%',xl:'77%'},
            padding:{xs:"20px", xl:"0"}
          }}
        >
          <Grid item xs={12} xl={7} sx={{padding:{md:'20px'}, color: 'black',               
                fontFamily: "Roboto",}}>
            <Typography
              sx={{
                fontWeight: '800',
                fontSize: {xs:'1.35rem', xl:'1.75rem'},           
                marginBottom: '20px', // Add space below this Typography

              }}
            >
              Vibration Analysis for Rotary Equipment   </Typography>
        
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: "1.125rem",
               
              }}
            >Harness AI for Advanced Vibration Analysis in Predictive Maintenance</Typography> 
            <Typography
              sx={{
                
                fontSize: "1rem",
                marginBottom: '16px', // Add space below this Typography
              }}
            >
             We empower industries with our cutting-edge AI-driven vibration analysis technology
	      for rotary equipment. Utilizing advanced machine learning algorithms and data analytics, 
	      our solution continuously monitors vibration data from critical assets like pumps, turbines, 
	      and compressors. By analyzing patterns and identifying anomalies—such as imbalances, misalignments, 
	      or bearing failures—our AI system provides early warnings of potential mechanical issues, enabling 
	      proactive maintenance interventions.</Typography>
			             <Typography
              sx={{
                fontWeight: '800',
                fontSize: "18px",               
                marginBottom: '8px', // Add space below this Typography
              }}
            >
             Key AI Capabilities:
            </Typography>
            <Box sx={{ paddingLeft: 2 }}>
              <Typography
                sx={{                 
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold' }}
                >
                  Continuous Data Monitoring: </Typography>
                &nbsp;Our technology delivers real-time insights into vibration patterns, 
ensuring that any performance deviations are promptly detected.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  marginBottom: 1,
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontFamily: "Roboto", fontWeight: 'bold' }}
                >
                  Predictive Analytics for Anomaly Detection: </Typography>
                &nbsp; Leveraging sophisticated algorithms,
 the system identifies early signs of wear and tear before they escalate into major failures.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  marginBottom: 3,
                  display: 'list-item',
                  listStyleType: 'disc',
                }}
              >
                <Typography
                  component="span"
                  sx={{  fontWeight: 'bold' }}
                >
                  Operational Cost Optimization:</Typography>
                &nbsp;By maximizing equipment uptime and minimizing unplanned repairs, 
our AI solution enhances overall operational efficiency.
              </Typography>
            </Box>
            <Typography
              sx={{               
                marginBottom: 2,
              }}
            >
              See how AI can transform your maintenance strategy! <strong>Experience our Vibration Analysis Demo</strong> and prevent issues before they happen.</Typography>
            <Button
              variant="contained" disabled
              sx={{
                fontWeight: "bold",                
                padding: {
                  xs: '10px',
                  sm: '10px',
                  md: '6px 40px',
                  lg: '8px 50px',
                  xl: '10px 60px',
                },
                mb:2,
                width: {
                  xs: '100%',
                  sm: 'auto',
                },               
                transition: 'transform 0.3s ease',
                borderRadius: "15px",
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              Coming soon...   </Button>
          </Grid>

         <Grid item xs={12} xl={5} sx={{ alignContent: "flex-end", justifyContent: "flex-end",  }}>
            <img src={vibrationImg} alt="AI Performance" style={{ height: isMobile ? '30vh' : '100%', width:'100%', }} />
          </Grid>
          <SectorsGrid
            title="Predictive Analytics is complex, we transform industries by simplifying it"
            sectors={sectorsDataRE}
            spacing={{ xs: 2, md: 3 }}
            itemBreakpoints={{ xs: 12, sm: 6, md: 4, lg: 2.4 }}
          />
                
        </Grid>
</Box> */}
<CallToActionBox
        message="Precise Predictive Analytics is a complex task, and we have the expertise to make it easy."
        buttonText="Contact us"
        onButtonClick={handleContactClick}
      />
      </Box>
    </Box>
  );
}

export default Boxes